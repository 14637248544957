import { graphql } from 'gatsby';
import type { FC } from 'react';
import { SocialIcon } from 'react-social-icons';
import styled from 'styled-components';
import { PageFrame } from '../components/PageFrame';

const SocialList = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem 0;

  a {
    &:hover {
      box-shadow: none;
    }
    margin-right: 1rem;
  }
`;

type ContactProps = {
  data: GatsbyTypes.ContactQueryQuery;
};

export const Contact: FC<ContactProps> = ({ data: { site } }) => {
  return (
    <PageFrame
      description={site?.siteMetadata?.description}
      title={`${site?.siteMetadata?.title ?? ''}.contact`}
    >
      <h1>Contact info</h1>
      <SocialList>
        <li>
          <SocialIcon url="https://www.linkedin.com/in/ristokeravuori/" />
          <a href="https://www.linkedin.com/in/ristokeravuori/">
            See my resume on LinkedIn
          </a>
        </li>
        <li>
          <SocialIcon url="https://twitter.com/themerrywhether" />
          <a href="https://twitter.com/themerrywhether">
            Contact me via Twitter
          </a>
        </li>
      </SocialList>
      <div>
        <a
          href="https://www.buymeacoffee.com/merrywhether"
          rel="noopener noreferrer"
          target="_blank"
        >
          Buy me a coffee?
        </a>
      </div>
    </PageFrame>
  );
};
Contact.displayName = 'Contact';
export default Contact;

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
